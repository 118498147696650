// Composables
import { createRouter, createWebHistory } from "vue-router";
import { useSelfStore } from "@/store/self";
import { authGuard } from "@auth0/auth0-vue";
import auth0 from "@/plugins/auth0";
import Socket from "@/socket";
import * as Sentry from "@sentry/browser";

interface PushSubscriptionNamespaceProperties {
  id: string;
  token: string;
  optedIn: boolean;
}

const routes = [
  {
    path: "/",
    name: "home",
    components: {
      appbar: () => import("@/components/Appbar.vue"),
      navigation: () => import("@/components/Navigation.vue"),
      default: () => import("@/layouts/home/Home.vue"),
    },
  },
  {
    path: "/callback",
    redirect: (to: any) => {
      return { path: "/" };
    },
  },
  {
    path: "/logout",
    name: "logout",
    redirect: (to: any) => {
      return { path: "/" };
    },
  },
  {
    path: "/agents",
    components: {
      appbar: () => import("@/components/Appbar.vue"),
      navigation: () => import("@/components/Navigation.vue"),
      default: () => import("@/views/Page.vue"),
    },
    props: {
      default: (route: any) => {
        return {
          title: route.meta.title,
          addRoute: route.meta.addRoute,
          tooltip: route.meta.tooltip,
        };
      },
    },
    children: [
      {
        path: "",
        name: "agents",
        component: () => import("@/components/agents/AgentsTable.vue"),
      },
      {
        path: ":id",
        name: "agent-editor",
        props: true,
        component: () => import("@/components/agents/AgentEditor.vue"),
      },
    ],
  },
  {
    path: "/conversations",
    components: {
      appbar: () => import("@/components/Appbar.vue"),
      navigation: () => import("@/components/Navigation.vue"),
      default: () => import("@/views/Page.vue"),
    },
    props: {
      default: (route: any) => {
        return {
          title: route.meta.title,
          addRoute: route.meta.addRoute,
          tooltip: route.meta.tooltip,
        };
      },
    },
    children: [
      {
        path: "",
        name: "conversations",
        component: () =>
          import("@/components/conversations/ConversationTable.vue"),
      },
      {
        path: ":id",
        name: "edit-conversation",
        props: true,
        meta: {
          title: "Edit Session",
          tooltip:
            "This page allows you to view a specific session and add additional information to it.",
        },
        component: () => import("@/layouts/conversation/Editor.vue"),
      },
    ],
  },
  {
    path: "/contacts",
    components: {
      appbar: () => import("@/components/Appbar.vue"),
      navigation: () => import("@/components/Navigation.vue"),
      default: () => import("@/views/Page.vue"),
    },
    children: [
      {
        path: "",
        meta: { title: "Contacts" },
        name: "contacts",
        component: () => import("@/layouts/contact/Home.vue"),
      },
    ],
  },
  {
    path: "/settings",
    alias: "/websites",
    props: {
      default: (route: any) => {
        return { title: route.meta.title, addRoute: route.meta.addRoute };
      },
    },
    components: {
      appbar: () => import("@/components/Appbar.vue"),
      navigation: () => import("@/components/Navigation.vue"),
      default: () => import("@/views/Page.vue"),
    },
    children: [
      {
        path: "",
        name: "settings",
        component: () => import("@/layouts/settings/Home.vue"),
      },
    ],
  },
  {
    path: "/setup",
    name: "wizard",
    component: () => import("@/views/Wizard.vue"),
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: () => import("@/views/Unauthorized.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to: any) => {
  if (
    to?.redirectedFrom?.path === "/callback" &&
    to?.redirectedFrom?.query?.error === "access_denied"
  ) {
    (auth0 as any)._client.options.authorizationParams.prompt = "login";
    (auth0 as any)._client.options.authorizationParams.error_code =
      to?.query?.error_description;

    await auth0.logout({
      logoutParams: {
        errorCode: to.query.error_description,
        error: to.query.error_description,
      },
    });
  }
});

router.beforeEach(authGuard);
router.beforeResolve(async (to, from) => {
  const selfStore = useSelfStore();
  if (!selfStore.hasRequiredInformationToFunction()) {
    try {
      await selfStore.refreshUser();
    } catch (e) {
      return await auth0.logout();
    }
  }
  if (!selfStore.hasRequiredInformationToFunction()) {
    return await auth0.logout();
  }
  if (selfStore.showWizard()) {
    if ((to?.name as string).indexOf("wizard") == -1) {
      return { name: "wizard" };
    } else {
      return true;
    }
  } else {
    if (!Socket.connected) {
      Socket.auth = {
        organizationId: selfStore.organization.id,
        userId: selfStore.user.id,
      };
      Socket.connect();
    }
    return true;
  }
});

// const pushSubscriptionChangeListener = async ({
//   previous,
//   current,
// }: {
//   previous: PushSubscriptionNamespaceProperties;
//   current: PushSubscriptionNamespaceProperties;
// }) => {
//   const selfStore = useSelfStore();
//   console.log("Push subscription changed");
//   if (current.optedIn) {
//     console.log("Logging in user now that they gave permission.");
//     (globalThis as any).OneSignalDeferred.push(async function (OneSignal: any) {
//       await OneSignal.login(selfStore.user.id);
//     });
//   } else {
//     (globalThis as any).OneSignalDeferred.push(async function (OneSignal: any) {
//       await OneSignal.logout(selfStore.user.id);
//     });
//   }
// };

router.afterEach((to, from) => {
  const selfStore = useSelfStore();
  try {
    if (Sentry) {
      Sentry.setUser(selfStore.user);
      Sentry.setContext("user", selfStore.user);
      Sentry.setContext("organization", selfStore.organization);
      Sentry.setContext("account", selfStore.account);
    }
    if (!selfStore.hasRequiredInformationToFunction()) {
      return;
    }
    if ((globalThis as any).dataLayer) {
      (globalThis as any).dataLayer.push({
        app_title: APP_NAME,
        page_path: to.path,
        page_name: to.name,
        screen_name: to.name,
        envrionment: NODE_ENV,
        release: APP_VERSION,
        user_id: selfStore.user.id,
        accountId: selfStore.account.id,
        organizationId: selfStore.organization.id,
      });
    }
    if ((globalThis as any).Appcues) {
      (globalThis as any).Appcues.identify(
        selfStore.account.id, // unique, required
        {
          createdAt: selfStore.user.createdAt, // Unix timestamp of user signup date
          accountId: selfStore.organization.id, // Current user's account ID
          userId: selfStore.user.id,
          firstName: selfStore.user.firstname, // current user's first name
          lastName: selfStore.user.lastname, // current user's last name
          name: selfStore.user.name,
          phone: selfStore.user.phone,
          companyName: selfStore.organization.name, // Current user’s company name
          email: selfStore.user.email, // Current user's email
        }
      );
      (globalThis as any).Appcues.group(selfStore.user.id, {
        firstName: selfStore.user.firstname, // current user's first name
        lastName: selfStore.user.lastname, // current user's last name
        name: selfStore.user.name,
      });
      (globalThis as any).Appcues.group(selfStore.organization.id, {
        // example properties
        companyName: selfStore.organization.name,
        createdAt: selfStore.organization.createdAt,
      });
      (globalThis as any).Appcues.track("Routed", {
        url: to.fullPath,
      });
    }
    // if ((globalThis as any).OneSignalDeferred) {
    //   (globalThis as any).OneSignalDeferred.push(async function (
    //     OneSignal: any
    //   ) {
    //     // Remove any existing event that might exist.
    //     OneSignal.User.PushSubscription.removeEventListener(
    //       "change",
    //       pushSubscriptionChangeListener
    //     );
    //     OneSignal.User.PushSubscription.addEventListener(
    //       "change",
    //       pushSubscriptionChangeListener
    //     );
    //     if (OneSignal && OneSignal.User.PushSubscription.optedIn) {
    //       /* Logs in the user under our Id instead of a new One Signal Id. */
    //       if (
    //         OneSignal?.User?.externalId != selfStore.user.id &&
    //         selfStore.user.id
    //       ) {
    //         await OneSignal.login(selfStore.user.id);
    //       }
    //     }
    //   });
    // }
  } catch (e) {
    if (Sentry) {
      Sentry.captureException(e);
    }
  }
});
router.onError((error, to) => {
  try {
    Sentry.captureException(error, {
      tags: {
        file: "router/index.ts",
      },
      extra: {
        to: to,
      },
    });
  } catch (e) {}
  if (error.message.includes("Failed to fetch dynamically imported module")) {
    window.location.href = to.fullPath;
  }
});
export default router;
